import { html } from '../../../core/scripts/util/util';
import { helpers } from '../../../core/scripts/util/helpers';
import animalCardTemplate from './animalCard.html';
import shareModal from './shareModal.html';
const translations = require('../../../core/scripts/data/i18nJSON.json');

export default vm => {
    if (vm.state.animalSearch.results.totalResults === 0) {
        return noResults();
    }
    return defaultTemplate(vm);
};

const defaultTemplate = vm => {
    const shareModalCompiledTemplate = shareModal(vm);

    return html`
        <div class="animalSearchBody${vm.state.ui.resultsLoading ? ' animalSearchBody_loading' : ''}">
            <div class="grid grid_gutter grid_gutterLg@minMd m-grid_stretch u-vr6x">
                ${drawResultsGrid(vm)}
            </div>
        </div>

        ${shareModalCompiledTemplate}
    `;
};

const smToutIndices = [9, 11, 13, 15];
const mdToutIndices = [15, 16];
const lgToutIndices = [7, 10, 13];
const xlToutIndices = [9, 13];

const smToutIndex = getRandomInt(smToutIndices);
const mdToutIndex = getRandomInt(mdToutIndices);
const lgToutIndex = getRandomInt(lgToutIndices);
const xlToutIndex = getRandomInt(xlToutIndices);

// Each key details out the positions for all the ads, content touts, and the
// saved search module that will be interspersed among the pet cards.
//
// NOTE: ads will appear directly AFTER the pet card based on indices present here
// NOTE: The indices below are zero based
const breakpointIndices = {
    oneAndTwoUp: {
        topAd: 1,
        midAd: 7,
        empty: -1,
        tout: smToutIndex,
        savedSearch: 3,
    },
    threeUp: {
        topAd: 2,
        midAd: 7,
        empty: 9,
        tout: mdToutIndex,
        savedSearch: 12,
    },
    fourUp: {
        topAd: 3,
        midAd: lgToutIndex < 10 ? 8 : 10,
        empty: lgToutIndex < 13 ? 11 : 13,
        tout: lgToutIndex,
        savedSearch: -1,
    },
    fiveUp: {
        topAd: 4,
        midAd: xlToutIndex < 13 ? 11 : 13,
        empty: xlToutIndex < 17 ? 15 : 17,
        tout: xlToutIndex,
        savedSearch: -1,
    },
};

// Assign the quantity of pet cards up that you need for each individual breakpoint
const adIndices = {
    sm: breakpointIndices.oneAndTwoUp,
    md: breakpointIndices.threeUp,
    lg: breakpointIndices.threeUp,
    xl: breakpointIndices.fourUp,
};

function getRandomInt(indices) {
    return indices[Math.floor(Math.random() * indices.length)];
}

const drawResultsGrid = vm => {
    const filtersState = vm.state.animalSearch.filters;
    const paginationState = vm.state.animalSearch.pagination;

    const isNewIfPublishedAfter = window.PF.pageConfig.hasOwnProperty('is_new_if_published_after')
        ? window.PF.pageConfig.is_new_if_published_after
        : false;

    const gridItemVm = Object.assign({}, vm, {
        isPetSearchResult: true,
        isNewIfPublishedAfter,
        paginationOffset: paginationState.offset,

        // controls which attributes of the animal are displayed in the card
        attributeDisplay: {
            gender: vm.state.animalSearch.filters.appliedFilters.indexOf('gender') === -1,
            // only show age if no age selection has been made
            age: filtersState.age.value.length !== 1,
        },
    });

    const gridHtml = vm.observedState.items.map((item, index) => {
        const itemHtml = resultGridItem(item, index, gridItemVm);
        const adHtml = checkAndPlaceInterrupters(index, gridItemVm);

        return `${itemHtml}${adHtml}`;
    });
    return gridHtml;
};

const resultGridItem = (animalData, index, vm) => {
    const animalCardVm = Object.assign({}, animalData, vm);

    animalCardVm.currentBreakpoint = vm.state.currentBreakpoint;
    animalCardVm.index = index;

    return animalCard(animalCardVm);
};

const checkAndPlaceInterrupters = (animalCardIndex, gridItemVm) => {
    const totalItems = gridItemVm.observedState.items.length;

    const lastIndex = totalItems - 1;
    const indices = adIndices[gridItemVm.state.currentBreakpoint];

    let adTemplate = '';

    // show ad if index matches ad index, or
    // show if there are no more pets and the ad hasn't been rendered yet
    if (animalCardIndex === indices.topAd || (animalCardIndex === lastIndex && animalCardIndex < indices.topAd)) {
        adTemplate = adTemplate + ad('SearchResults1', 'petsearch_top', 'LEADERBOARD, LARGE_MOBILE_BANNER');
    }

    // anything below this point only renders with complete results
    if (totalItems < 20) {
        return adTemplate;
    }

    if (animalCardIndex === indices.midAd) {
        adTemplate = adTemplate + ad2();
    }

    if (animalCardIndex === indices.empty) {
        adTemplate = adTemplate + emptyCell();
    }

    if (animalCardIndex === indices.savedSearch) {
        adTemplate = adTemplate + savedSearch();
    }

    if (animalCardIndex === indices.tout) {
        // adTemplate = adTemplate + tout(gridItemVm.observedState.tout);
        const toutResult = tout(
            '/adopt-or-get-involved/adopting-pets/',
            'Pet Adoption Resources',
            '',
            '',
            'Explore helpful articles on adoption, pet care, and more',
            'Learn More About Pet Adoption'
        );
        // Note: Even putting a test div here will not show up in the search results
        // Even weirded -- Dog search results only have Lorem Ipsum content in the card -- all other animal type searches have blank touts
        adTemplate = adTemplate + toutResult;
    }

    return adTemplate;
};

const animalCard = result => {
    return html`
        <div class="grid-col grid-col_1/2@minSm grid-col_1/3@minMd grid-col_1/3@minLg grid-col_1/4@minXl">
            ${animalCardTemplate(result)}
        </div>
    `;
};

const emptyCell = () => html`
    <div class="grid-col grid-col_1/2@minSm grid-col_1/3@minMd grid-col_1/3@minLg grid-col_1/4@minXl"></div>
`;

const ad = (adId, positionTargetId, adPoolIds = '') => html`
    <div class="grid-col">
        <pfdc-ad-container
            slot-id="${adId}"
            position-target-id="${positionTargetId}"
            search-ad
            ${adPoolIds && `ad-pool-ids="${adPoolIds}"`}
            style="max-height: 100px"
        ></pfdc-ad-container>
    </div>
`;

const ad2 = () => html`
    <div class="grid-col grid-col_1/3@minMd grid-col_1/4@minXl">
        <div class="resultInterrupter">
            <div class="resultInterrupter-tall">
                <pfdc-ad slot-id="SearchResults2" search-ad non-responsive lazy-load></pfdc-ad>
            </div>
        </div>
    </div>
`;

const savedSearch = () => html`
    <div class="grid-col">
        <div class="createSavedSearchCard_container">
            <pfdc-create-saved-search
                observe-state="animalSearch.savedSearch.createSearchFormOpen"
                template="createPetSearchCard"
            ></pfdc-create-saved-search>
        </div>
    </div>
`;

/* eslint-disable indent */
const tout = (src, title, iconUrl, iconAlt, excerpt, ctaText) => {
    return html`
        <div class="grid-col grid-col_2/3@minMd grid-col_1/2@minXl">
            ${helpers.if(
                src,
                html`
                    <a
                        class="actionCard m-actionCard_bgAlt m-actionCard_padSm"
                        href="${src}"
                        target="_blank"
                        pf-mix-click="trackingBridge.ensighten('Consumer026_027');"
                        data-href="${src}"
                        data-title="${helpers.if(
                            title,
                            html`
                                ${title}
                            `,
                            ''
                        )}"
                        data-position="content-animal-search-results-tout"
                    >
                `,
                html`
                    <div class="actionCard m-actionCard_bgAlt m-actionCard_padSm"></div>
                `
            )}
            <div class="actionCard-body actionCard-body_center-content">
                <svg class="loader loader_leftTopCorner" role="img" focusable="false" viewBox="25 25 50 50">
                    <circle
                        class="path"
                        cx="50"
                        cy="50"
                        r="20"
                        fill="none"
                        stroke-width="5"
                        stroke="#fff"
                        stroke-dasharray="39.25, 117.75"
                    />
                </svg>
                <div class="u-alignCenter">
                    ${helpers.if(
                        iconUrl,
                        html`
                            <img src="${iconUrl}" alt="${iconAlt}" role="presentation" class="actionCard-body-image" />
                        `,
                        ''
                    )}
                </div>
                <h3 class="txt txt_h3 m-txt_colorAltS1 u-vr1x">
                    <pf-truncate line-count="2">
                        ${helpers.if(
                            title,
                            html`
                                ${title}
                            `,
                            ''
                        )}
                    </pf-truncate>
                </h3>
                <p class="txt m-txt_colorAltS1">
                    <pf-truncate line-count="2">
                        ${helpers.if(
                            excerpt,
                            html`
                                ${excerpt}
                            `,
                            ''
                        )}
                    </pf-truncate>
                </p>
            </div>

            ${helpers.if(
                src,
                html`
                    <div class="actionCard-footer">
                        <div class="actionCard-footer-cta">${ctaText}</div>
                    </div>
                </a>
            `,
                html`
                </div>
            `
            )}
        </div>
    `;
};

const noResults = result => html`
    <h2 class="txt txt_h1 m-txt_colorDark u-vr8x">
        ${translations['consumer.animal_search.results_not_found']}
    </h2>
    <div class="grid grid_gutter grid_gutterLg@minMd m-grid_stretch u-vr6x">
        ${savedSearch()} ${ad('SearchResults1', 'petsearch_top', 'LEADERBOARD, LARGE_MOBILE_BANNER')}
    </div>
`;
